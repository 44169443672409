const RETAIL_PRICE_TYPE = '1c73b026-b4f6-11ee-0a80-05fc00037ce1';

/**
 * Возвращает розничную цену продукта
 * @param salePriceTypesSimple Массив salePriceTypesSimple
 */
export const getRetailPrice = (salePriceTypesSimple) => {
  const salesPrice = salePriceTypesSimple.find(salesPrice => salesPrice.priceType === RETAIL_PRICE_TYPE);
  if (salesPrice) return salesPrice.value;
  return 0;
};