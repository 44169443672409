const StoriesEmptyItem = () => {
    return (
        <div className="stories-empty-item">
            <div className="stories-empty-img"></div>
            <div className="stories-empty-title"></div>
        </div>
    )
}

export default StoriesEmptyItem
