export const statuses = [
  {
    text: "Резерв не собирать",
    color: "#6A4B1D",
    id: "5ca5f71d-bfc5-11ed-0a80-00c300161a21",
  },
  {
    text: "Новый",
    color: "#E68116",
    id: "949070dc-eb02-11ec-0a80-0234000c96a0",
  },
  {
    text: "Подтвержден Менеджером",
    color: "#00489A",
    id: "20ac279a-08ba-11ed-0a80-06490018891f",
  },
  {
    text: "Подтвержден Юг Менеджером",
    color: "#EB62A0",
    id: "c2b7234f-415f-11ed-0a80-09130018686d",
  },
  {
    text: "Самовывоз Менеджером",
    color: "#019EE3",
    id: "f203c5f8-0d64-11ed-0a80-095800179443",
  },
  {
    text: "Срочно Менеджером",
    color: "#E92A1A",
    id: "af4a44ed-d125-11ed-0a80-063400219e99",
  },
  {
    text: "Собираем",
    color: "#A466BD",
    id: "bbaeac64-29ca-11ed-0a80-07740007646a",
  },
  {
    text: "Собираем Юг",
    color: "#8D1530",
    id: "43e2b53a-05c3-11ee-0a80-10980002bd08",
  },
  {
    text: "Отгружен",
    id: "949072a4-eb02-11ec-0a80-0234000c96a3",
    color: "#038737",
  },
  {
    text: "Отменен",
    id: "94907552-eb02-11ec-0a80-0234000c96a6",
    color: "#000000",
  },
  {
    text: "Заказ под клиента не начат",
    color: "#E92A1A",
    id: "b2454135-d533-11ed-0a80-10eb000b3c5d",
  },
  {
    text: "Заказ под клиента План вкл.",
    color: "#A1C616",
    id: "10466ed2-14d7-11ee-0a80-1470000d0f9a",
  },
];
