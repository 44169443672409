import React from 'react';
import CatalogBlock from '../components/CatalogBlock/CatalogBlock';

function Catalog() {
  return (
    <>
      <CatalogBlock />
    </>
  );
}

export default Catalog;
