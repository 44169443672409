import React from 'react';

const LogoSmall = ({width, height}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={width || '115'}
    height={height || '31'}
    viewBox='0 0 115 31'
    fill='none'
  >
    <path
      d='M61.3948 6.84766C58.3773 6.84766 56.2415 7.53919 55.0863 8.57648L55.3512 7.13399H51.5306L49.1611 20.1677H52.9817L54.4102 13.0363C54.861 10.5781 56.7798 8.63321 59.7325 8.24963C62.7839 7.84984 64.6604 9.75965 64.2237 12.1827L62.7614 20.1704H66.6242L68.0978 12.0774C68.6613 8.97087 65.6211 6.84766 61.3948 6.84766Z'
      fill='white'
    />
    <path
      d='M86.4052 3.16992L83.5877 18.6105L83.3003 20.18H75.7916L75.8029 20.1665C71.2441 20.0773 67.7363 17.4706 68.1899 13.5807C68.6689 9.46394 73.0614 6.92473 77.344 6.98145H80.139L79.8714 8.28078H77.9752C74.0419 8.21594 72.0442 11.109 72.0668 14.3236C72.0921 16.8844 74.1799 18.7996 76.9693 18.9131H79.784L82.6212 3.20234L86.4052 3.16992Z'
      fill='white'
    />
    <path
      d='M83.5995 18.6113L83.3008 20.1808L83.5882 18.6113H83.5995Z'
      fill='white'
    />
    <path
      d='M21.7792 3.16402L18.6826 20.166L22.4639 20.1309L25.5603 3.12891L21.7792 3.16402Z'
      fill='white'
    />
    <path
      d='M3.09076 3.66211L0 20.1697L3.78675 20.1346L6.16748 6.45795L3.09076 3.66211Z'
      fill='white'
    />
    <path
      d='M114.713 8.37733L115 7.051C113.969 7.02669 109.351 7.02939 108.221 7.02939C105.207 7.02939 103.353 9.07967 102.933 11.4865L101.417 20.2036H105.314L106.962 11.3163C107.385 8.88788 108.514 8.49889 110.83 8.39894C112.014 8.35032 113.518 8.35032 114.713 8.37733Z'
      fill='white'
    />
    <path
      d='M92.6911 12.8362H98.7037C99.1489 11.2073 98.498 8.32503 94.6887 8.30612C91.7754 8.29261 88.9917 10.4942 88.7466 14.33C88.5832 16.8908 90.1919 18.8709 93.1475 18.8709H97.2554L97.0216 20.1648H92.336C88.2112 20.2431 84.0075 17.4392 84.709 13.579C85.374 9.92149 89.5721 6.95277 94.6578 6.99059C99.6758 7.02841 102.868 9.28939 102.282 13.6925C102.259 13.8627 102.257 13.9059 102.209 14.1355H92.4713L92.6911 12.8362Z'
      fill='white'
    />
    <path
      d='M40.2878 12.8381H46.3004C46.7455 11.2093 46.0947 8.32698 42.2854 8.30807C39.3721 8.29457 36.5884 10.4961 36.3432 14.332C36.1798 16.8928 37.7887 18.8728 40.7443 18.8728H44.8523L44.6184 20.1668H39.9329C35.808 20.2451 31.6041 17.4411 32.3057 13.581C32.9706 9.92345 37.1688 6.95473 42.2544 6.99254C47.2725 7.03036 50.4647 9.29135 49.8787 13.6945C49.8562 13.8646 49.8506 13.9079 49.8055 14.1375H40.0681L40.2878 12.8381Z'
      fill='white'
    />
    <path
      d='M18.8017 17.6636C18.1678 19.6355 14.7585 20.7025 9.89263 20.2325C8.04997 20.0488 6.09177 19.6598 4.81543 18.9494C4.81543 18.9494 5.15353 16.9558 5.16762 16.945C6.06078 17.5636 8.5796 18.5766 10.3237 18.8278C13.3469 19.2628 14.9276 18.5199 14.5106 17.3908C14.0711 16.1941 10.1152 14.6597 9.42777 14.3167C6.29467 12.7445 5.76771 11.194 6.24951 9.72448C6.90881 7.76334 11.2958 6.48833 15.6263 7.10963C17.5507 7.38516 18.9257 7.95783 20.0809 8.73851L18.7313 10.0378C18.0156 9.51108 16.5618 8.61155 14.9276 8.38464C12.358 8.01726 10.1209 8.32521 9.97435 9.68667C9.82784 11.0319 13.4849 12.3177 15.2318 13.2254C18.0916 14.703 19.2835 16.2076 18.8017 17.6636Z'
      fill='white'
    />
    <path
      d='M27.7467 11.9634C26.8732 12.5145 26.9605 13.6571 27.7128 14.2947L33.9396 20.1673H29.2202L25.0193 16.4044C23.5654 15.1023 23.8416 12.7846 25.58 11.7068L33.1619 7.22266L35.4469 7.25508L27.7467 11.9634Z'
      fill='white'
    />
    <path
      d='M6.9425 1.66594L6.48616 4.46178L1.71313 0.128906H5.57046C6.42136 0.128906 7.07492 0.858256 6.9425 1.66594Z'
      fill='#DD0613'
    />
    <path
      d='M23.3105 28.0531L23.5867 27.7479C24.215 28.3043 24.7926 28.5609 25.635 28.5609C26.4915 28.5609 27.0776 28.099 27.0776 27.4588C27.0776 26.8807 26.7592 26.5431 25.4632 26.2946C24.091 26.0298 23.5388 25.5841 23.5388 24.7386C23.5388 23.9255 24.3137 23.2988 25.3731 23.2988C26.2042 23.2988 26.7536 23.5176 27.3312 23.9633L27.0607 24.2848C26.5226 23.8391 25.9845 23.6662 25.3561 23.6662C24.5165 23.6662 23.9614 24.1281 23.9614 24.7143C23.9614 25.2924 24.2714 25.6436 25.6266 25.911C26.948 26.1703 27.5031 26.6214 27.5031 27.4372C27.5031 28.3205 26.7114 28.931 25.6097 28.931C24.6996 28.9283 23.998 28.6393 23.3105 28.0531Z'
      fill='white'
    />
    <path
      d='M28.3086 27.6784C28.3086 26.8491 29.0524 26.3791 30.1287 26.3791C30.7063 26.3791 31.1064 26.4493 31.5065 26.552V26.3791C31.5065 25.5741 30.9936 25.1581 30.1371 25.1581C29.6299 25.1581 29.2158 25.2905 28.8326 25.4796L28.6861 25.1419C29.1425 24.9393 29.5906 24.7988 30.1626 24.7988C30.7261 24.7988 31.174 24.9474 31.4754 25.2364C31.7515 25.5012 31.901 25.8712 31.901 26.3629V28.8508H31.5093V28.1863C31.2247 28.5698 30.7091 28.9453 29.9174 28.9453C29.1398 28.9453 28.3086 28.5239 28.3086 27.6784ZM31.5149 27.357V26.9031C31.1627 26.8167 30.7007 26.7221 30.1034 26.7221C29.2215 26.7221 28.734 27.0976 28.734 27.6676C28.734 28.2457 29.306 28.5833 29.94 28.5833C30.788 28.586 31.5149 28.0863 31.5149 27.357Z'
      fill='white'
    />
    <path
      d='M33.1738 24.8621H33.5739V25.605C33.85 25.135 34.3149 24.7676 35.0757 24.7676C36.1266 24.7676 36.7409 25.4564 36.7409 26.41V28.8492H36.3407V26.4802C36.3407 25.659 35.8589 25.1187 35.0193 25.1187C34.2022 25.1187 33.5766 25.713 33.5766 26.5423V28.8492H33.1766V24.8621H33.1738Z'
      fill='white'
    />
    <path
      d='M38.0234 23.2988H38.5193V23.8229H38.0234V23.2988ZM38.0713 24.8629H38.4714V28.8527H38.0713V24.8629Z'
      fill='white'
    />
    <path
      d='M40.1059 27.8262V25.2141H39.5029V24.8629H40.1059V23.5879H40.506V24.8629H41.9654V25.2141H40.506V27.7884C40.506 28.3584 40.8581 28.5637 41.3371 28.5637C41.5399 28.5637 41.7119 28.5259 41.9486 28.4232V28.7825C41.7373 28.877 41.5315 28.9229 41.2723 28.9229C40.6271 28.9229 40.1059 28.5853 40.1059 27.8262Z'
      fill='white'
    />
    <path
      d='M42.6445 27.6784C42.6445 26.8491 43.3883 26.3791 44.4646 26.3791C45.0422 26.3791 45.4424 26.4493 45.8425 26.552V26.3791C45.8425 25.5741 45.3297 25.1581 44.4732 25.1581C43.966 25.1581 43.5517 25.2905 43.1686 25.4796L43.022 25.1419C43.4785 24.9393 43.9265 24.7988 44.4985 24.7988C45.062 24.7988 45.51 24.9474 45.8115 25.2364C46.0876 25.5012 46.2369 25.8712 46.2369 26.3629V28.8508H45.8452V28.1863C45.5606 28.5698 45.0451 28.9453 44.2533 28.9453C43.4785 28.9453 42.6445 28.5239 42.6445 27.6784ZM45.8509 27.357V26.9031C45.4987 26.8167 45.0367 26.7221 44.4393 26.7221C43.5574 26.7221 43.0701 27.0976 43.0701 27.6676C43.0701 28.2457 43.6419 28.5833 44.2759 28.5833C45.124 28.586 45.8509 28.0863 45.8509 27.357Z'
      fill='white'
    />
    <path
      d='M47.5137 24.8644H47.9137V25.9989C48.2406 25.2885 48.9337 24.7698 49.7564 24.8022V25.2236H49.7169C48.7533 25.2236 47.9137 25.9206 47.9137 27.2172V28.8515H47.5137V24.8644Z'
      fill='white'
    />
    <path
      d='M50.297 29.9545L50.435 29.6195C50.6547 29.7222 50.8351 29.7681 51.145 29.7681C51.5789 29.7681 51.8634 29.525 52.1734 28.8307L50.1758 24.8652H50.6406L52.3707 28.4309L53.8724 24.8652H54.312L52.5088 28.9955C52.1425 29.8329 51.7169 30.1301 51.1224 30.1301C50.8012 30.1247 50.5731 30.0706 50.297 29.9545Z'
      fill='white'
    />
    <path
      d='M57.1777 24.8639H57.6257L58.8823 28.3674L60.1389 24.8477H60.4574L61.714 28.3674L62.9706 24.8639H63.4045L61.8802 28.8834H61.5365L60.2967 25.4663L59.0486 28.8834H58.7077L57.1777 24.8639Z'
      fill='white'
    />
    <path
      d='M63.8975 27.6784C63.8975 26.8491 64.6413 26.3791 65.7176 26.3791C66.2952 26.3791 66.6953 26.4493 67.0954 26.552V26.3791C67.0954 25.5741 66.5825 25.1581 65.726 25.1581C65.2188 25.1581 64.8047 25.2905 64.4215 25.4796L64.275 25.1419C64.7314 24.9393 65.1795 24.7988 65.7514 24.7988C66.3149 24.7988 66.7628 24.9474 67.0643 25.2364C67.3404 25.5012 67.4898 25.8712 67.4898 26.3629V28.8508H67.0981V28.1863C66.8136 28.5698 66.298 28.9453 65.5063 28.9453C64.7314 28.9453 63.8975 28.5239 63.8975 27.6784ZM67.1038 27.357V26.9031C66.7516 26.8167 66.2896 26.7221 65.6923 26.7221C64.8104 26.7221 64.3228 27.0976 64.3228 27.6676C64.3228 28.2457 64.8949 28.5833 65.5288 28.5833C66.3769 28.586 67.1038 28.0863 67.1038 27.357Z'
      fill='white'
    />
    <path
      d='M68.7627 24.8644H69.1628V25.9989C69.4896 25.2885 70.1827 24.7698 71.0054 24.8022V25.2236H70.966C70.0024 25.2236 69.1628 25.9206 69.1628 27.2172V28.8515H68.7627V24.8644Z'
      fill='white'
    />
    <path
      d='M71.3779 26.8476C71.3779 25.6914 72.2261 24.7676 73.3531 24.7676C74.5196 24.7676 75.2606 25.6752 75.2606 26.8557C75.2606 26.9259 75.2605 26.9502 75.252 27.0124H71.8006C71.8738 28.0064 72.6177 28.5845 73.4319 28.5845C74.0771 28.5845 74.5166 28.3117 74.8604 27.9578L75.1365 28.1928C74.7138 28.6304 74.2152 28.9438 73.415 28.9438C72.3218 28.9438 71.3779 28.1064 71.3779 26.8476ZM74.8267 26.6693C74.7703 25.8643 74.2884 25.1214 73.3333 25.1214C72.5077 25.1214 71.8739 25.786 71.7978 26.6693H74.8267Z'
      fill='white'
    />
  </svg>
);

export default LogoSmall;
